import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from 'src/components/RTL';
import useAuth from 'src/hooks/useAuth';
import useScrollReset from 'src/hooks/useScrollReset';
import useSettings from 'src/hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from 'src/theme';
import SpinnerScreen from 'src/components/layout/SpinnerScreen';
import { isMobile } from 'react-device-detect';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  if (isMobile) {
    if (window.location.origin.includes('community.accziom.com')) {
      window.location.href = window.location.href.replace('community.accziom.com', 'community.m.accziom.com');
      return <div />;
    }
  }

  useScrollReset();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'MOBILE'
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster
          position="top-center"
        />
        {auth.isInitialized ? content : <SpinnerScreen />}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
