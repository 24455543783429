import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Badge,
  Typography,
  Zoom,
  Avatar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import getInitials, { getInitialBackground } from 'src/utils/getInitials';

interface TopbarItemProps {
  icon: any;
  title: string;
  href: string;
  value?: number;
  onClick: any;
  selected: boolean;
  enable: boolean;
}

const TopbarItem: FC<TopbarItemProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { icon: Icon, title, href, onClick, selected, value, enable } = props;
  const [status, setStatus] = useState<string>('normal');
  const navigate = useNavigate();
  // useEffect(() => {
  //   // eslint-disable-next-line no-underscore-dangle
  //   if (rootRef?.current?._container) {
  //     // eslint-disable-next-line no-underscore-dangle
  //     rootRef.current._container.scrollHeight = height;
  //   }
  // }, [height]);

  return (
    <Box
      sx={{
        width: '100%',
        px: 0.25
      }}
    >
      <Box
        id={`topitem_${title}`}
        ref={anchorRef}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        onClick={() => {
          if (href === '#') {
            onClick?.();
            setStatus('normal');
          } else {
            navigate(href);
            setStatus('normal');
          }
        }}
      >
        {!selected && status === 'normal' && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Badge
              badgeContent={
                value !== 0 ? (
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginTop: '-2px'
                    }}
                  >
                    {value}
                  </Typography>
                ) : value
              }
              variant="dot"
              color="error"
            >
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  backgroundColor: 'transparent',
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {(typeof Icon === 'string') ? (
                  <Avatar
                    src={Icon}
                    sx={{
                      height: 24,
                      width: 24,
                      bgcolor: getInitialBackground(getInitials(Icon)),
                      color: (thm) => (enable ? thm.palette.text.primary : thm.palette.divider),
                      fontSize: '12px',
                      fontWeight: 'bold'
                    }}
                  >
                    {getInitials(Icon)}
                  </Avatar>
                ) : (
                  <Icon />
                )}
              </Box>
            </Badge>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                color: (thm) => thm.palette.text.secondary,
                justifyContent: href === '/organization' ? 'flex-start' : 'center',
              }}
            >
              <Typography
                sx={{
                  textOverflow: href === '/organization' ? 'ellipsis' : 'clip',
                  overflow: href === '/organization' ? 'hidden' : 'inherit',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        {!selected && status === 'hover' && (
          <Box
            sx={{
              width: '100%!important',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Badge
              badgeContent={
                value !== 0 ? (
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginTop: '-2px'
                    }}
                  >
                    {value}
                  </Typography>
                ) : value
              }
              variant="dot"
              color="error"
            >
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  backgroundColor: 'transparent',
                  color: (theme) => theme.palette.text.primary
                }}
              >
                {(typeof Icon === 'string') ? (
                  <Avatar
                    src={Icon}
                    sx={{
                      height: 24,
                      width: 24,
                      bgcolor: getInitialBackground(getInitials(Icon)),
                      color: (thm) => thm.palette.text.primary,
                      fontSize: '12px',
                      fontWeight: 'bold'
                    }}
                  >
                    {getInitials(Icon)}
                  </Avatar>
                ) : (
                  <Icon />
                )}
              </Box>
            </Badge>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                color: (thm) => thm.palette.text.secondary,
                justifyContent: href === '/organization' ? 'flex-start' : 'center',
              }}
            >
              <Typography
                sx={{
                  textOverflow: href === '/organization' ? 'ellipsis' : 'clip',
                  overflow: href === '/organization' ? 'hidden' : 'inherit',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        {!selected && status === 'down' && (
          <Box
            sx={{
              width: '100%!important',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Badge
              badgeContent={
                value !== 0 ? (
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginTop: '-2px'
                    }}
                  >
                    {value}
                  </Typography>
                ) : value
              }
              variant="dot"
              color="error"
            >
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  backgroundColor: 'transparent',
                  color: (theme) => theme.palette.text.primary
                }}
              >
                {(typeof Icon === 'string') ? (
                  <Avatar
                    src={Icon}
                    sx={{
                      height: 20,
                      width: 20,
                      bgcolor: getInitialBackground(getInitials(Icon)),
                      color: (thm) => thm.palette.text.primary,
                      fontSize: '10px',
                      fontWeight: 'bold'
                    }}
                  >
                    {getInitials(Icon)}
                  </Avatar>
                ) : (
                  <Icon style={{ height: '20px', width: '20px' }} />
                )}
              </Box>
            </Badge>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                color: (thm) => thm.palette.text.secondary,
                justifyContent: href === '/organization' ? 'flex-start' : 'center',
              }}
            >
              <Typography
                sx={{
                  textOverflow: href === '/organization' ? 'ellipsis' : 'clip',
                  overflow: href === '/organization' ? 'hidden' : 'inherit',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        {selected && (
          <Box
            sx={{
              width: '100%!important',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Badge
              badgeContent={
                value !== 0 ? (
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginTop: '-2px'
                    }}
                  >
                    {value}
                  </Typography>
                ) : value
              }
              variant="dot"
              color="error"
            >
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  backgroundColor: 'transparent',
                  color: (theme) => theme.palette.text.primary
                }}
              >
                {(typeof Icon === 'string') ? (
                  <Avatar
                    src={Icon}
                    sx={{
                      height: 24,
                      width: 24,
                      bgcolor: getInitialBackground(getInitials(Icon)),
                      color: (thm) => thm.palette.text.primary,
                      fontSize: '12px',
                      fontWeight: 'bold'
                    }}
                  >
                    {getInitials(Icon)}
                  </Avatar>
                ) : (
                  <Icon />
                )}
              </Box>
            </Badge>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                color: (thm) => thm.palette.text.primary,
                justifyContent: href === '/organization' ? 'flex-start' : 'center',
              }}
            >
              <Typography
                sx={{
                  textOverflow: href === '/organization' ? 'ellipsis' : 'clip',
                  overflow: href === '/organization' ? 'hidden' : 'inherit',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        <Box flex="1" />
        <Zoom
          in={selected}
          style={{
            transitionDelay: '100ms'
          }}
          timeout={{
            enter: 400,
            exit: 600
          }}
        >
          <Box
            sx={{
              height: '2px',
              width: '100%',
              backgroundColor: (theme) => theme.palette.primary.main,
              borderRadius: '4px',
            }}
          />
        </Zoom>
      </Box>
    </Box>
  );
};

TopbarItem.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  href: PropTypes.string,
  value: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.any,
  enable: PropTypes.bool
};

export default TopbarItem;
