import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { AppBar, Box, CircularProgress, Divider, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { experimentalStyled, ThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'src/store';
import { useLocation, useNavigate } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import { setActiveOrgId, setOrgStructures } from 'src/slices/organization';
import { initChatApi, initNeedReloads } from 'src/worker/init';
import { lambdaGetOrgActivatedMembersOfUser } from 'src/aws/lambdaDispatch';
import toast from 'react-hot-toast';
import OrgPopover from './OrgPopover';
import { NAVBAR_HEIGHT, REQUIRE_AUTH_MODE, REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE } from 'src/globals';
import useSearch from 'src/hooks/useSearch';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NavMenuItem, { Item } from 'src/components/interact/NavMenuItem';
// import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import NavItem from '../interact/NavItem';
import CircleType from 'circletype';
import { cleanTwilioStatus } from 'src/slices/chat';
import { LoadingStatus } from 'src/slices/user';
import useSettings from 'src/hooks/useSettings';
import { createCustomTheme } from 'src/theme';
import { BiSearch } from 'react-icons/bi';
import useAuth from 'src/hooks/useAuth';
import { getActiveOrgMinInfo } from 'src/utils/getActiveOrgInfo';

const DashboardItems: Item[] = [
  // {
  //   title: 'Public Forum',
  //   href: '/public',
  //   onClick: null,
  //   authRequired: REQUIRE_NONE_MODE,
  //   icon: TravelExploreIcon,
  //   items: []
  // },
  {
    title: 'Sign In',
    href: '/login',
    onClick: null,
    authRequired: REQUIRE_NOT_AUTH_MODE,
    icon: AccountCircleOutlinedIcon,
    items: []
  }
];

const searchBarUrls = {
  notAuthUrlsForSame: [],
  notAuthUrlsForStart: [
    '/public'
  ],
  authUrlsForSame: [],
  authUrlsForStart: [
    '/public',
    '/mine'
  ],
};

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: '3px 0px 5px rgba(0, 0, 0, .5)',
    // borderColor: theme.palette.background.paper,
    // borderBottomWidth: '1px',
    // borderBottomStyle: 'solid'
  })
);

const LARGE_LOGO_SIZE = 64;
const TITLE_TEXT_RADIUS = 20;

const DashboardNavbar: FC = () => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const searchApi = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { userInfo, searchInfo, isSearching } = useSelector((state) => state.user);
  const { memberInfos, orgInfos, activeOrgId, orgsNeedReload } = useSelector((state) => state.organization);
  const { twilioStatus } = useSelector((state) => state.chat);
  const [isSwitchingOrg, setIsSwitchingOrg] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const activeOrgMinInfo = getActiveOrgMinInfo();
  const displayTitle = getUserDisplayName(activeOrgMinInfo).toUpperCase();
  const href = `/${pathname.split('/').slice(1).join('/')}`;
  const enabledSearch = (!isAuthenticated && (searchBarUrls.notAuthUrlsForSame.includes(href) || searchBarUrls.notAuthUrlsForStart.some((subHref) => href.startsWith(subHref))))
    || (isAuthenticated && (searchBarUrls.authUrlsForSame.includes(href) || searchBarUrls.authUrlsForStart.some((subHref) => href.startsWith(subHref))));
  let circleType = null;

  const handleSearch = async (q: string): Promise<void> => {
    searchApi.current?.onSearch?.(q);
  };

  const handleGotoAccziom = () => {
    if (!isAuthenticated) window.open('https://go.accziom.com', '_blank');
    else {
      const orgInfo = orgInfos.find((org) => org.organizationId === activeOrgId);
      window.open(`https://${orgInfo.domainName}.go.accziom.com`, '_blank');
    }
  };

  const setDashboardItems = () => {
    if (!mounted.current) return;
    const mm : Item[] = [];
    DashboardItems.forEach((item) => {
      const butItem = {
        ...item,
        ...(item.title === 'Go To Accziom' && { onClick: handleGotoAccziom })
      };
      if (!isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE].includes(item.authRequired)) mm.push(butItem);
      if (isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_AUTH_MODE].includes(item.authRequired)) mm.push(butItem);
    });
    setItems(mm);
  };

  useEffect(() => {
    setDashboardItems();
  }, [mounted, activeOrgId, pathname, isAuthenticated]);

  const getDataOnLoad = () => {
    if (!userInfo || loading || !orgsNeedReload) return;
    setLoading(true);
    lambdaGetOrgActivatedMembersOfUser(userInfo.uid)
      .then((res) => {
        const { memberInfos: members, orgInfos: organizations, categoryInfos: categories, roleInfos: strRoles, teamInfos: teams } = res;
        const roles = [];
        strRoles.forEach((item) => {
          roles.push({
            rid: item.rid,
            oid: item.oid,
            name: item.name,
            level: item.level,
            department: JSON.parse(item.department),
            assignment: JSON.parse(item.assignment),
            production: JSON.parse(item.production),
            transaction: JSON.parse(item.transaction),
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          });
        });
        const memberData = [];
        const orgData = [];
        const categoryData = [];
        const roleData = [];
        const teamData = [];
        const teamIds = [];
        members.forEach((member) => {
          if (member.rid) {
            const role = roles.find((item) => item.rid === member.rid);
            const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
            const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
            const teamCount = member.tids.length;
            if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
              memberData.push(member);
              const organization = organizations.find((item) => item.organizationId === member.oid);
              if (organization) orgData.push(organization);
              const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
              if (category) categoryData.push(category);
              if (role) roleData.push(role);
              member.tids.forEach((tid) => {
                if (!teamIds.includes(tid)) {
                  teamIds.push(tid);
                  const team = teams.find((item) => item.tid === tid);
                  teamData.push(team);
                }
              });
            }
          } else {
            memberData.push(member);
            const organization = organizations.find((item) => item.organizationId === member.oid);
            if (organization) orgData.push(organization);
            const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
            if (category) categoryData.push(category);
          }
        });
        memberData.sort((member0, member1) => { if (member0.type < member1.type) return 1; if (member0.type > member1.type) return -1; return 0; }); // sort by type, client first, member second
        dispatch(setOrgStructures(memberData, orgData, categoryData, roleData, teamData));
        dispatch(cleanTwilioStatus());
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        toast.error('Failed to get organization information!');
      })
      .finally(() => {
        if (mounted.current) setLoading(false);
      });
  };

  const switchOrg = async (orgId: string): Promise<void> => {
    if (process.env.NODE_ENV === 'production') {
      const orgInfo = orgInfos.find((item) => item.organizationId === orgId);
      if (orgInfo?.domainName) {
        const domain = `${orgInfo.domainName}.community.m.accziom.com`;
        if (domain !== window.location.host) window.location.href = `https://${domain}`;
      }
      return;
    }
    setIsSwitchingOrg(true);
    try {
      const curMember = memberInfos.find((member) => member.oid === orgId);
      const chatLoaded = await initChatApi(curMember.mid, dispatch);
      if (chatLoaded) {
        dispatch(setActiveOrgId(orgId));
        initNeedReloads(dispatch);
      } else toast.error('Chat has not been initialized.');
    } catch (err) {
      toast.error('Switching organization has been failed.');
    } finally {
      if (mounted.current) setIsSwitchingOrg(false);
    }
  };

  useEffect(() => {
    if (mounted.current && isAuthenticated) getDataOnLoad();
  }, [mounted, orgsNeedReload, isAuthenticated]);

  useEffect(() => {
    const circularEle = document.getElementById('circular_title');
    if (mounted.current && isAuthenticated && circularEle) {
      document.getElementById('circular_title').innerHTML = displayTitle.length < 14 ? displayTitle : `${displayTitle.substring(0, 12)}...`;
      circleType = new CircleType(document.getElementById('circular_title'))
        .dir(1)
        .radius(LARGE_LOGO_SIZE / 2 + TITLE_TEXT_RADIUS);
    }
    return () => {
      if (mounted.current && isAuthenticated) {
        if (circleType) {
          circleType.destroy();
          circleType = null;
        }
      }
    };
  }, [mounted, isAuthenticated, displayTitle]);

  const { settings } = useSettings();
  const themeSearch = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });

  const [query, setQuery] = useState<string>('');
  useEffect(() => {
    if (mounted.current) setQuery('');
  }, [mounted, pathname]);

  return (
    <DashboardNavbarRoot>
      <Box
        sx={{
          height: NAVBAR_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          px: {
            sm: '70px',
            md: '70px',
            lg: 'calc(1.5% + 70px)',
            xl: 'calc(4% + 70px)'
          }
        }}
      >
        {enabledSearch ? (
          <ThemeProvider theme={themeSearch}>
            <TextField
              size="small"
              sx={{
                width: 'calc(100% - 80px)',
                pl: 1
              }}
              InputProps={{
                sx: {
                  fontSize: 15,
                  borderRadius: '36px'
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <IconButton
                      onClick={() => navigate(isAuthenticated ? '/home' : '/')}
                      sx={{
                        p: 0
                      }}
                    >
                      <img
                        src="/favicon.svg"
                        alt="Logo"
                        style={{
                          height: 28,
                          width: 28,
                          marginLeft: '0px'
                        }}
                      />
                    </IconButton>
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: '100%',
                        py: 1.5,
                        pl: 1
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    {!!searchInfo?.hasButton && (
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: 1000,
                          right: 0,
                          top: 0,
                          width: 43,
                          height: 39,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            width: 37,
                            height: 32,
                            bgcolor: isSearching ? 'transparent' : 'primary.main',
                            borderRadius: 1,
                            color: 'background.paper',
                            '&:hover': {
                              bgcolor: '#0f68a3'
                            },
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: '.3s'
                          }}
                          onClick={() => {
                            if (!isSearching) handleSearch(query);
                          }}
                        >
                          {isSearching ? (
                            <CircularProgress
                              size={16}
                            />
                          ) : (
                            <BiSearch
                              style={{
                                width: 18,
                                height: 18
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                setQuery(e.target.value);
                if (!searchInfo?.hasButton) handleSearch(e.target.value);
              }}
              placeholder={searchInfo?.placeHolder ? searchInfo.placeHolder : ''}
            />
          </ThemeProvider>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: 'calc(100% - 50px)',
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }}
          >
            <NavItem
              sourcePath="/favicon.svg"
              altName="Logo"
              height={28}
              href={isAuthenticated ? '/home' : '/'}
            />
          </Box>
        )}
        <Box flexGrow={1} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {
            items.map((item) => {
              let disabled = false;
              if (['/public', '/mine'].includes(item.href)) {
                if (twilioStatus.loadingStatus === LoadingStatus.LOADING && twilioStatus.option !== item.href.slice(1)) disabled = true;
              }
              return (
                <NavMenuItem
                  key={item.title}
                  isHorizontal={false}
                  data={item}
                  value={0}
                  disabled={disabled}
                />
              );
            })
          }
        </Box>
        {isAuthenticated && (
          <OrgPopover
            loading={loading}
            onChange={switchOrg}
            isSwitchingOrg={isSwitchingOrg}
          />
        )}
      </Box>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
