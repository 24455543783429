import { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { AppBar, AppBarProps, Box, Toolbar } from '@material-ui/core';
import { experimentalStyled, ThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'src/store';
import { matchPath, useLocation } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import { setOrgStructures } from 'src/slices/organization';
import { lambdaGetOrgActivatedMembersOfUser } from 'src/aws/lambdaDispatch';
import toast from 'react-hot-toast';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { REQUIRE_AUTH_MODE, REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE } from 'src/globals';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Item } from 'src/components/interact/NavMenuItem';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import useAuth from 'src/hooks/useAuth';
import { cleanTwilioStatus } from 'src/slices/chat';
import { LoadingStatus } from 'src/slices/user';
import useSettings from 'src/hooks/useSettings';
import { createCustomTheme } from 'src/theme';
import TopbarItem from './TopbarItem';

const AccziomLogoIcon: FC<{ style: { width: string; height: string; } }> = ({ style }) => (
  <Box
    style={style}
  >
    <img
      alt="logo"
      src="/favicon.svg"
      style={{
        width: '100%',
        height: '100%'
      }}
    />
  </Box>
);

const DashboardItems: Item[] = [
  {
    title: 'Go To Accziom',
    href: '#',
    onClick: null,
    authRequired: REQUIRE_NONE_MODE,
    icon: AccziomLogoIcon,
    items: [],
  },
  {
    title: 'Public Forum',
    href: '/public',
    onClick: null,
    authRequired: REQUIRE_NONE_MODE,
    icon: TravelExploreIcon,
    items: []
  },
  {
    title: 'My Channels',
    href: '/mine',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: RateReviewIcon,
    items: []
  },
  {
    title: 'Sign In',
    href: '/login',
    onClick: null,
    authRequired: REQUIRE_NOT_AUTH_MODE,
    icon: AccountCircleOutlinedIcon,
    items: []
  }
];

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: '3px 0px 5px rgba(0, 0, 0, .5)',
    // borderColor: theme.palette.background.paper,
    // borderBottomWidth: '1px',
    // borderBottomStyle: 'solid'
  })
);

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  trigger?: boolean;
}

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, trigger, ...other } = props;
  const mounted = useMounted();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { userInfo } = useSelector((state) => state.user);
  const { orgInfos, activeOrgId, orgsNeedReload } = useSelector((state) => state.organization);
  const { twilioStatus } = useSelector((state) => state.chat);
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function isSamePath(href): boolean {
    if (!href) return false;
    return !!matchPath({
      path: href,
      caseSensitive: false,
      end: false
    }, pathname);
  }

  const handleGotoAccziom = () => {
    if (!isAuthenticated) window.open('https://go.m.accziom.com', '_blank');
    else {
      const orgInfo = orgInfos.find((org) => org.organizationId === activeOrgId);
      window.open(`https://${orgInfo.domainName}.go.m.accziom.com`, '_blank');
    }
  };

  const setDashboardItems = () => {
    if (!mounted.current) return;
    const mm : Item[] = [];
    DashboardItems.forEach((item) => {
      const butItem = {
        ...item,
        ...(item.title === 'Go To Accziom' && { onClick: handleGotoAccziom })
      };
      if (!isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE].includes(item.authRequired)) mm.push(butItem);
      if (isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_AUTH_MODE].includes(item.authRequired)) mm.push(butItem);
    });
    setItems(mm);
  };

  useEffect(() => {
    setDashboardItems();
  }, [mounted, activeOrgId, pathname, isAuthenticated]);

  const getDataOnLoad = () => {
    if (!userInfo || loading || !orgsNeedReload) return;
    setLoading(true);
    lambdaGetOrgActivatedMembersOfUser(userInfo.uid)
      .then((res) => {
        const { memberInfos: members, orgInfos: organizations, categoryInfos: categories, roleInfos: strRoles, teamInfos: teams } = res;
        const roles = [];
        strRoles.forEach((item) => {
          roles.push({
            rid: item.rid,
            oid: item.oid,
            name: item.name,
            level: item.level,
            department: JSON.parse(item.department),
            assignment: JSON.parse(item.assignment),
            production: JSON.parse(item.production),
            transaction: JSON.parse(item.transaction),
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          });
        });
        const memberData = [];
        const orgData = [];
        const categoryData = [];
        const roleData = [];
        const teamData = [];
        const teamIds = [];
        members.forEach((member) => {
          if (member.rid) {
            const role = roles.find((item) => item.rid === member.rid);
            const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
            const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
            const teamCount = member.tids.length;
            if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
              memberData.push(member);
              const organization = organizations.find((item) => item.organizationId === member.oid);
              if (organization) orgData.push(organization);
              const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
              if (category) categoryData.push(category);
              if (role) roleData.push(role);
              member.tids.forEach((tid) => {
                if (!teamIds.includes(tid)) {
                  teamIds.push(tid);
                  const team = teams.find((item) => item.tid === tid);
                  teamData.push(team);
                }
              });
            }
          } else {
            memberData.push(member);
            const organization = organizations.find((item) => item.organizationId === member.oid);
            if (organization) orgData.push(organization);
            const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
            if (category) categoryData.push(category);
          }
        });
        memberData.sort((member0, member1) => { if (member0.type < member1.type) return 1; if (member0.type > member1.type) return -1; return 0; }); // sort by type, client first, member second
        dispatch(setOrgStructures(memberData, orgData, categoryData, roleData, teamData));
        dispatch(cleanTwilioStatus());
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        toast.error('Failed to get organization information!');
      })
      .finally(() => {
        if (mounted.current) setLoading(false);
      });
  };

  useEffect(() => {
    if (mounted.current && isAuthenticated) getDataOnLoad();
  }, [mounted, orgsNeedReload, isAuthenticated]);

  const { settings } = useSettings();
  const themeSearch = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'TOPBAR'
  });

  const isApple = /webOS|iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

  return (
    <ThemeProvider theme={themeMobile}>
      <DashboardNavbarRoot
        sx={{
          top: 'auto',
          bottom: 0,
        }}
        {...other}
      >
        <Toolbar
          sx={{
            minHeight: isApple && trigger ? '72px' : '48px',
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            px: 1
          }}
        >
          <ThemeProvider theme={themeSearch}>
            {
              items.map((item) => {
                let disabled = false;
                if (['/public', '/mine'].includes(item.href)) {
                  if (twilioStatus.loadingStatus === LoadingStatus.LOADING && twilioStatus.option !== item.href.slice(1)) disabled = true;
                }
                return (
                  <Box
                    key={item.title}
                    sx={{
                      width: `calc(100% / ${items.length})`,
                      flexDirection: 'row'
                    }}
                  >
                    <TopbarItem
                      icon={item.icon}
                      href={item.href}
                      title={item.title}
                      value={0}
                      onClick={item.onClick}
                      selected={isSamePath(item.href)} // value === i
                      enable={!disabled}
                    />
                  </Box>
                );
              })
            }
          </ThemeProvider>
        </Toolbar>
      </DashboardNavbarRoot>
    </ThemeProvider>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  trigger: PropTypes.bool
};

export default DashboardNavbar;
