import { Box } from '@material-ui/core';
import type { FC } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { useTheme } from '@material-ui/core/styles';
import 'src/components/display/loading.css';
import PropTypes from 'prop-types';

const SpinnerScreen: FC<{ noTopbar?: boolean }> = ({ noTopbar }) => {
  const color = useTheme().palette.primary.main;
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 2000,
        ...(!noTopbar ? ({
          left: 0,
          position: 'fixed',
          top: 0
        }) : {})
      }}
    >
      <img
        alt="Logo"
        src="/favicon.svg"
        style={{
          width: '60px'
        }}
        className="loading-logo"
      />
      <Loader
        type="ThreeDots"
        height="40"
        width="80"
        color={color}
      />
    </Box>
  );
};

SpinnerScreen.propTypes = {
  noTopbar: PropTypes.bool
};

export default SpinnerScreen;
