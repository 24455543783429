import { chatApi } from 'src/apis/chatApi';
import { lambdaGetOrgActivatedMembersOfUser, lambdaGetUserInfo } from 'src/aws/lambdaDispatch';
import { cleanUpChat } from 'src/slices/chat';
import { clearUserState, LoadingStatus, setLoadingStatus, setMemberNeedReload, setUserInfo } from 'src/slices/user';
import { setCurOrgNeedReload, setActiveOrgId, cleanUserMinInfos, clearOrganizationState, setOrgStructures } from 'src/slices/organization';
import toast from 'react-hot-toast';
import intervalJobs from 'src/utils/intervalJobs';
import { CHECK_INTERVAL_MINUTES } from 'src/globals';
import { clearTaxonomyState, setAssetCategoryNeedReload, setChatCategoryNeedReload, setOrgCategoryNeedReload, setServiceCategoryNeedReload } from 'src/slices/taxonomy';

let isLoading: boolean = false;

export const initChatApi = (uid: string, dispatch): Promise<boolean> => {
  dispatch(cleanUpChat());
  chatApi.cleanUp();
  dispatch(cleanUserMinInfos());
  return new Promise((resolve) => {
    chatApi.initConnection(uid, dispatch)
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const initFreeChatApi = (dispatch): Promise<boolean> => {
  dispatch(cleanUpChat());
  chatApi.cleanUp();
  dispatch(cleanUserMinInfos());
  return new Promise((resolve) => {
    chatApi.initFreeConnection(dispatch)
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const initNeedReloads = (dispatch) => {
  dispatch(setCurOrgNeedReload(true));
  dispatch(setMemberNeedReload(true));
  dispatch(setOrgCategoryNeedReload(true));
  dispatch(setServiceCategoryNeedReload(true));
  dispatch(setAssetCategoryNeedReload(true));
  dispatch(setChatCategoryNeedReload(true));
};

export const initializeStates = (dispatch) => {
  dispatch(clearTaxonomyState());
  dispatch(clearOrganizationState());
  dispatch(clearUserState());
  dispatch(cleanUpChat());
};

const initialLoad = (id: string, checkLive, dispatch, logout, domainName: string): void => {
  if (isLoading) return;

  intervalJobs(CHECK_INTERVAL_MINUTES * 60 * 1000, checkLive, true);
  isLoading = true;
  dispatch(setLoadingStatus(LoadingStatus.LOADING));
  initializeStates(dispatch);

  // console.log(id);
  lambdaGetUserInfo(id) // for normal start
  // lambdaGetUserInfo('28322ca1-f94f-48b2-8268-a78149521218') // for James
  // lambdaGetUserInfo('fcb2bd19-79da-49ab-97b8-098d8e6abfcb') // for Noble & Associates
  // lambdaGetUserInfo('469d19ff-2c34-4409-81b3-2feea660b7e7') // for LodgeiT
    .then((ui) => {
      if (ui) {
        dispatch(setUserInfo(ui));
        lambdaGetOrgActivatedMembersOfUser(ui.uid)
          .then((res) => {
            const { memberInfos: members, orgInfos: organizations, categoryInfos: categories, roleInfos: strRoles, teamInfos: teams } = res;
            const roles = [];
            strRoles.forEach((item) => {
              roles.push({
                rid: item.rid,
                oid: item.oid,
                name: item.name,
                level: item.level,
                department: JSON.parse(item.department),
                assignment: JSON.parse(item.assignment),
                production: JSON.parse(item.production),
                transaction: JSON.parse(item.transaction),
                createdAt: item.createdAt,
                updatedAt: item.updatedAt
              });
            });
            const memberInfos = [];
            const orgInfos = [];
            const categoryInfos = [];
            const roleInfos = [];
            const teamInfos = [];
            const teamIds = [];
            members.forEach((member) => {
              if (member.rid) {
                const role = roles.find((item) => item.rid === member.rid);
                const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
                const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
                const teamCount = member.tids.length;
                if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
                  memberInfos.push(member);
                  const organization = organizations.find((item) => item.organizationId === member.oid);
                  if (organization) orgInfos.push(organization);
                  const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
                  if (category) categoryInfos.push(category);
                  if (role) roleInfos.push(role);
                  member.tids.forEach((tid) => {
                    if (!teamIds.includes(tid)) {
                      teamIds.push(tid);
                      const team = teams.find((item) => item.tid === tid);
                      teamInfos.push(team);
                    }
                  });
                }
              } else {
                memberInfos.push(member);
                const organization = organizations.find((item) => item.organizationId === member.oid);
                if (organization) orgInfos.push(organization);
                const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
                if (category) categoryInfos.push(category);
              }
            });
            memberInfos.sort((member0, member1) => { if (member0.type < member1.type) return 1; if (member0.type > member1.type) return -1; return 0; }); // sort by type, client first, member second
            dispatch(setOrgStructures(memberInfos, orgInfos, categoryInfos, roleInfos, teamInfos));
            let firstOrg = null;
            let firstMember = null;
            if (orgInfos.length > 0) {
              if (domainName) {
                firstOrg = orgInfos.find((organizationInfo) => organizationInfo.domainName === domainName);
                if (firstOrg) firstMember = memberInfos.find((member) => member.oid === firstOrg.organizationId);
              } else {
                firstOrg = orgInfos.at(0);
                firstMember = memberInfos.find((member) => member.oid === firstOrg.organizationId);
              }
            }
            if (firstOrg && firstMember) {
              if (process.env.NODE_ENV === 'production' && !domainName) window.location.href = `https://${firstOrg.domainName}.community.m.accziom.com`;
              else {
                dispatch(setActiveOrgId(firstOrg.organizationId));
                dispatch(setLoadingStatus(LoadingStatus.COMPLETED));
                isLoading = false;
              }
            }
          })
          .catch((e) => {
            console.log('Get organization error : ', JSON.stringify(e));
            dispatch(setActiveOrgId(null));
            dispatch(setLoadingStatus(LoadingStatus.NONE));
            logout();
            toast.error('Failed to get organizations!');
            isLoading = false;
          });
      }
    })
    .catch((e) => {
      console.log('User not exists.', JSON.stringify(e));
      dispatch(setActiveOrgId(null));
      dispatch(setLoadingStatus(LoadingStatus.NONE));
      logout();
      toast.error('Failed to get user information!');
      isLoading = false;
    });
};

export default initialLoad;
